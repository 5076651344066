<template>
<slot :internal="internal"></slot>
<slot v-if="internal.pending" name="pending"></slot>
<slot v-if="internal.result !== undefined" name="result" :result="internal.result"></slot>
<slot v-if="internal.error !== undefined" name="error" :error="internal.error"></slot>
</template>

<script>

import { ref, watch, onMounted } from "vue"

export default {
    props: {
        promise: {
            type: [Function, Object],
            default: undefined,
        },
        delayRefresh: {
            type: [Number],
            default: 100,
        },
        saveResult: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {
        const internal = ref({
            pending: true,
            result: undefined,
            error: undefined,
        });

        const alreadyDone = ref(false);

        watch(() => props.promise, () => {
            if(alreadyDone.value) return;
            let isPending = true;
            setTimeout(() => {
                if(isPending) ResetInternal();
            }, props.delayRefresh);

            (typeof props.promise == 'function' ? props.promise() : props.promise).then((result) => {
                isPending = false;
                internal.value.pending = false;
                internal.value.result = result;
            }).catch((error) => {
                isPending = false;
                internal.value.pending = false;
                internal.value.error = error;
            }).then(() => {
                if(props.saveResult) alreadyDone.value = true;
            })

        }, { immediate: true })

        function ResetInternal(){
            internal.value.pending = true;
            internal.value.result = undefined;
            internal.value.error = undefined;
        }
        
        onMounted(() => {
        })

        return {
            internal
        }
    },
}
</script>